export const APP_NAME = 'Eduvels Admin';
export const AWS_BASE_URL = process.env.REACT_APP_AWS_BASE_URL;
export const AWS_S3_CUSTOM_DOMAIN = process.env.REACT_APP_AWS_S3_CUSTOM_DOMAIN;
export const AWS_STORAGE_BUCKET_NAME = process.env.REACT_APP_AWS_STORAGE_BUCKET_NAME;
export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
export const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
export const AWS_S3_REGION_NAME = process.env.REACT_APP_AWS_S3_REGION_NAME;

export const API_KEY = process.env.REACT_APP_API_KEY;
export const PRODUCTION = false;
export const BASE_URL = PRODUCTION?process.env.REACT_APP_BASE_URL:process.env.REACT_APP_LIVE_BASE_URL;

// HTTPS requests
export const GET = "get";
export const POST = "post";
export const PUT = "put";
export const PATCH = "patch";
export const DELETE = "delete";
export const OPTION = "options";

//constants 
export const AUTH = "auth";
export const DASHBOARD = "dashboard";
export const USER = "user";
export const SUBJECT = "subject";
export const SUBSCRIPTION = "subscription";
export const LANGUAGE = "language";
export const TEACHER = "teacher";
export const ADMIN = "admin";
export const GAMES = "games";
export const DATE_FORMAT = "YYYY-MM-DD";
export const ABBREVIATED_DATE_FORMAT = "D MMM";
export const ABBREVIATED_FULL_DATE_FORMAT = "D MMM YYYY";
export const DATE_TIME_FORMAT = "DD MMM YYYY, hh:mm A";
export const DATE_TIME_MONTHE_WISE_FORMAT = "MMM DD YYYY, hh:mm A";
export const DATE_DASH_TIME_FORMAT = "D-M-YYYY h:mm A";
export const DATE_YEAR_WISE_DASH_TIME_FORMAT = "YYYY-MM-DD h:mm A";
export const DATE_LOCAL_DASH_TIME_FORMAT = "YYYY-MM-DDTHH:mm";
export const DATE_DOT_TIME_FORMAT = "DD.MM.YYYY H:mm";
export const DATE_SLASH_FORMAT = "DD/MM/YYYY";
export const SEARCH_DELAY = 1000;
export const ITEM_PER_PAGE = 11;
export const COUNT_PER_PAGE = 12;

// model
export const SWEET_ALERT_OK_COLOR = "#d33";
export const SWEET_ALERT_CANCEL_COLOR = "#3085d6";
